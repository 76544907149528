<template>
  <v-select
    dense
    outlined
    v-if="isMobile"
    label="Operation"
    item-value="value"
    item-text="name"
    v-model="operation"
    :items="operations"
  ></v-select>
  <v-autocomplete
    dense
    v-else
    outlined
    item-value="value"
    item-text="name"
    label="Operation"
    v-model="operation"
    :items="operations"
  ></v-autocomplete>
</template>

<script>
import {
  mapGetters,
  mapMutations,
} from 'vuex';

const FIELD_NAME = 'machineoperation';

export default {
  name: 'OperationSelection',
  computed: {
    ...mapGetters('webApp', ['filters']),
    ...mapGetters('userDashboard', ['operationList']),
    isOperationFilterInactive() {
      return !Object
        .keys(this.filters)
        .includes(FIELD_NAME);
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    operations() {
      let operations = [];
      if (this.operationList && this.operationList.length) {
        operations = [{
          name: 'All Operations',
          value: 'All',
        }, ...this.operationList];
      }
      return operations;
    },
    operation: {
      get() {
        const operationFilter = this.filters && this.filters[FIELD_NAME];
        if (operationFilter) {
          return operationFilter.value;
        }
        return this.operations && this.operations.length && this.operations[0].value;
      },
      set(operationVal) {
        this.setOperationFilter(operationVal);
      },
    },
  },
  created() {
    if (this.operations && this.operations.length) {
      if (this.isOperationFilterInactive) {
        this.setOperationFilter(this.operations[0].value);
      }
    }
  },
  methods: {
    ...mapMutations('webApp', ['setFilter']),
    setOperationFilter(val) {
      this.setFilter({
        field: FIELD_NAME,
        value: {
          value: val,
          operation: 'eq',
        },
      });
    },
  },
  watch: {
    operations(val) {
      if (val && val.length) {
        if (this.isOperationFilterInactive) {
          this.setOperationFilter(val[0].value);
        }
      }
    },
  },
};
</script>
