<template>
  <v-select
    dense
    outlined
    v-if="isMobile"
    label="Cell"
    item-value="value"
    item-text="name"
    v-model="cell"
    :items="cells"
  ></v-select>
  <v-autocomplete
    dense
    v-else
    outlined
    item-value="value"
    item-text="name"
    label="Cell"
    v-model="cell"
    :items="cells"
  ></v-autocomplete>
</template>

<script>
import {
  mapGetters,
  mapMutations,
} from 'vuex';

const FIELD_NAME = 'machinecell';

export default {
  name: 'CellSelection',
  computed: {
    ...mapGetters('webApp', ['filters']),
    ...mapGetters('userDashboard', ['cellList']),
    isCellFilterInactive() {
      return !Object
        .keys(this.filters)
        .includes(FIELD_NAME);
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    cells() {
      let cells = [];
      if (this.cellList && this.cellList.length) {
        cells = [{
          name: 'All Cells',
          value: 'All',
        }, ...this.cellList];
      }
      return cells;
    },
    cell: {
      get() {
        const cellFilter = this.filters && this.filters[FIELD_NAME];
        if (cellFilter) {
          return cellFilter.value;
        }
        return this.cells && this.cells.length && this.cells[0].value;
      },
      set(cellVal) {
        this.setCellFilter(cellVal);
      },
    },
  },
  created() {
    if (this.cells && this.cells.length) {
      if (this.isCellFilterInactive) {
        this.setCellFilter(this.cells[0].value);
      }
    }
  },
  methods: {
    ...mapMutations('webApp', ['setFilter']),
    setCellFilter(val) {
      this.setFilter({
        field: FIELD_NAME,
        value: {
          value: val,
          operation: 'eq',
        },
      });
    },
  },
  watch: {
    cells(val) {
      if (val && val.length) {
        if (this.isCellFilterInactive) {
          this.setCellFilter(val[0].value);
        }
      }
    },
  },
};
</script>
