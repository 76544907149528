<template>
  <v-container fluid>
    <portal to="app-header">
      {{ $t('userDashboard') }}
    </portal>
    <component :is="component" />
  </v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import AdminDashboard from '../components/AdminDashboard.vue';
import PlanHeadDashboard from '../components/PlanHeadDashboard.vue';
import ProductionPlannerDashboard from '../components/ProductionPlannerDashboard.vue';
import ProductionSupervisorDashboard from '../components/ProductionSupervisorDashboard.vue';
import MachineDashboard from '../components/MachineDashboard.vue';

export default {
  name: 'UserDashboard',
  components: {
    AdminDashboard,
    PlanHeadDashboard,
    ProductionPlannerDashboard,
    MachineDashboard,
    ProductionSupervisorDashboard,
  },
  computed: {
    ...mapGetters('user', ['roleName']),
    ...mapState('user', ['licenses']),
    isCnc() {
      return this.licenses.length === 1 && this.licenses[0].assetId === 9;
    },
    component() {
      if (this.isCnc) {
        return 'machine-dashboard';
      }
      switch (this.roleName) {
        case 'planthead':
          return 'production-supervisor-dashboard';
        case 'planner':
          return 'production-planner-dashboard';
        case 'supervisor':
          return 'production-supervisor-dashboard';
        default:
          return 'production-supervisor-dashboard';
      }
    },
  },
};
</script>
