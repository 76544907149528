<template>
  <v-row>
    <v-col cols="12" class="pb-0">
      PERFORMANCE
    </v-col>
    <v-col cols="4" class="pt-0">
      <v-select
        dense
        :items="operatorList"
        v-model="operator"
        hide-details
      ></v-select>
    </v-col>
    <v-col cols="8" class="pt-0">
      <v-text-field
        type="number"
        dense
        v-model.number="num"
        suffix="%"
        hide-details
      ></v-text-field>
    </v-col>
  </v-row>
</template>

<script>
import {
  mapMutations,
  mapGetters,
} from 'vuex';

const FIELD_NAME = 'performance';

export default {
  name: 'PerformanceFilter',
  data() {
    return {
      operator: '',
      operatorList: [{
        text: '<=',
        value: 'lte',
      }, {
        text: '>=',
        value: 'gte',
      }],
      num: '',
    };
  },
  created() {
    if (this.filter) {
      this.num = this.filter.value;
      this.operator = this.filter.operation;
    } else {
      this.num = 0;
      this.operator = 'gte';
    }
  },
  computed: {
    ...mapGetters('webApp', ['filters']),
    filter() {
      return this.filters && this.filters[FIELD_NAME];
    },
    filterUpdate() {
      return `${this.num}|${this.operator}`;
    },
  },
  methods: {
    ...mapMutations('webApp', ['setFilter']),
  },
  watch: {
    filterUpdate() {
      if ((this.num || this.num === 0) && this.operator) {
        this.setFilter({
          field: FIELD_NAME,
          value: {
            value: this.num,
            operation: this.operator,
          },
        });
      }
    },
  },
};
</script>
