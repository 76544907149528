<template>
  <v-navigation-drawer app right temporary width="290" v-model="drawerInput">
    <v-toolbar flat>
      <v-toolbar-title> Filters </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="drawerInput = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <perfect-scrollbar style="height: calc(100% - 64px)">
      <v-card flat class="transparent">
        <v-card-text>
          <cell-filter />
          <operation-filter />
          <performance-filter />
          <status-filter class="mt-6" />
        </v-card-text>
      </v-card>
    </perfect-scrollbar>
  </v-navigation-drawer>
</template>

<script>
import PerformanceFilter from './filters/PerformanceFilter.vue';
import StatusFilter from './filters/StatusFilter.vue';
import CellFilter from './filters/CellFilter.vue';
import OperationFilter from './filters/OperationFilter.vue';

export default {
  name: 'ConfigDrawer',
  props: {
    value: {
      type: Boolean,
    },
  },
  components: {
    PerformanceFilter,
    StatusFilter,
    CellFilter,
    OperationFilter,
  },
  computed: {
    drawerInput: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
};
</script>
