<template>
 <div style="height:100%">
   <portal to="app-header">
      {{ currentShift }} Dashboard
      <v-btn
        small
        outlined
        class="text-none ml-2"
        @click="drawer = !drawer"
      >
        <v-icon small left>mdi-filter-variant</v-icon>
        Filters
      </v-btn>
      <v-btn
        icon
        small
        class="ml-2"
        @click="fetchData"
      >
        <v-icon>mdi-refresh</v-icon>
      </v-btn>
    </portal>
    <config-drawer v-model="drawer" />
    <v-data-table
      :headers="headers"
      :items="filteredMachines"
      :loading="fetchingMachines"
      disable-pagination
      hide-default-footer
      fixed-header
    >
  <!-- eslint-disable-next-line -->
    <template #item.machinestatus="{ item }">
      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-avatar
            size="16"
            v-on="on"
            v-bind="attrs"
            :color="getStatusColor(item.machinestatus)"
          ></v-avatar>
        </template>
        <span>{{ getStatusTooltip(item.machinestatus) }}</span>
      </v-tooltip>
    </template>
    <!-- eslint-disable-next-line -->
    <template #item.partname="{ item }">
      <span v-if="item.production.length">
        {{ item.production[0].partname || '-' }}
      </span>
    </template>
    <!-- eslint-disable-next-line -->
    <template #item.produced="{ item }">
      <span v-if="item.production.length">
        {{ item.production[0].produced || '-' }}
      </span>
    </template>
    <!-- eslint-disable-next-line -->
    <template #item.timeTarget="{ item }">
      <span v-if="item.production.length">
        {{ item.production[0].timeTarget || '-' }}
      </span>
    </template>
    <!-- eslint-disable-next-line -->
    <template #item.avgCycletime="{ item }">
      <span v-if="item.production.length">
        {{ item.production[0].avgCycletime
          ? parseFloat(item.production[0].avgCycletime, 10).toFixed(2)
          : '-' }} secs
      </span>
    </template>
    <!-- eslint-disable-next-line -->
    <template #item.stdCycletime="{ item }">
      <span v-if="item.production.length">
        {{ item.production[0].stdCycletime
          ? parseFloat(item.production[0].stdCycletime, 10).toFixed(2)
          : '-' }} secs
      </span>
    </template>
    <!-- eslint-disable-next-line -->
    <template #item.performance="{ item }">
      <span>
        {{ parseFloat(item.performance, 10).toFixed(2) }} %
      </span>
    </template>
    </v-data-table>
 </div>
</template>

<script>
import {
  mapState, mapActions, mapMutations, mapGetters,
} from 'vuex';
import ConfigDrawer from './ConfigDrawer.vue';

export default {
  name: 'MachineDashboard',
  data() {
    return {
      sseClient: null,
      timeout: null,
      timeInterval: null,
      readyState: 0,
      states: [
        { text: 'Connecting', color: 'warning' },
        { text: 'Open', color: 'success' },
        { text: 'Closed', color: 'error' },
      ],
      loading: false,
      drawer: false,
      fetchingMachines: false,
      headers: [
        { text: 'Cell', value: 'machinecell', sortable: false },
        { text: 'Operation', value: 'machineoperation', sortable: false },
        { text: 'Machine', value: 'machinename', sortable: false },
        { text: 'Part', value: 'partname', sortable: false },
        { text: 'Produced Qty', value: 'produced', sortable: false },
        { text: 'Target Qty', value: 'timeTarget', sortable: false },
        { text: 'Average Cycle Time', value: 'avgCycletime', sortable: false },
        { text: 'Standard Cycle Time', value: 'stdCycletime', sortable: false },
        {
          text: 'Machine Status',
          value: 'machinestatus',
          align: 'center',
        },
        { text: 'Performance', value: 'performance' },
      ],
    };
  },
  components: {
    ConfigDrawer,
  },
  computed: {
    ...mapGetters('userDashboard', ['filteredMachines']),
    ...mapState('userDashboard', ['currentShift', 'currentDate', 'machines']),
    ...mapState('webApp', ['config', 'storageLocation']),
    toggleDrawer: {
      get() {
        return this.configDrawer;
      },
      set(val) {
        this.setConfigDrawer(val);
      },
    },
  },
  created() {
    this.fetchData();
  },
  beforeDestroy() {
    if (this.sseClient) {
      this.sseClient.close();
    }
    clearTimeout(this.timeout);
    clearInterval(this.timeInterval);
  },
  watch: {
    config: {
      deep: true,
      handler(val) {
        localStorage.setItem(this.storageLocation.machineDashboard, JSON.stringify(val));
      },
    },
  },
  methods: {
    ...mapMutations('webApp', ['setConfig', 'resetConfig']),
    ...mapActions('userDashboard', [
      'getMachines',
      'fetchMachines',
      'getBusinessTime',
    ]),
    ...mapMutations('userDashboard', [
      'setMachine',
    ]),
    async fetchData() {
      this.fetchingMachines = true;
      const config = localStorage.getItem(this.storageLocation.machineDashboard);
      if (config) {
        this.setConfig(JSON.parse(config));
      } else {
        this.resetConfig();
      }
      await this.getBusinessTime();
      await this.getMachines();
      await this.fetchMachines();
      const self = this;
      this.timeInterval = setInterval(async () => {
        await self.getBusinessTime();
      }, 60000);
      if (this.sseClient) {
        this.sseClient.close();
      }
      this.sseInit();
      this.fetchingMachines = false;
    },
    getStatusColor(val) {
      if (val === 'UP') {
        return 'success';
      }
      if (val === 'DOWN') {
        return 'error';
      }
      if (val === 'NOPLAN') {
        return 'info';
      }
      return '';
    },
    getStatusTooltip(val) {
      if (val === 'UP') {
        return 'Operational';
      }
      if (val === 'DOWN') {
        return 'Stopped';
      }
      if (val === 'NOPLAN') {
        return 'Idle';
      }
      return '';
    },
    sseInit() {
      this.sseClient = new EventSource('/sse/asm');
      this.readyState = this.sseClient.readyState;
      this.sseClient.onopen = () => {
        if (this.timeout != null) {
          clearTimeout(this.timeout);
        }
      };
      this.sseClient.addEventListener('shift', (e) => {
        this.readyState = e.target.readyState;
        const eventData = JSON.parse(JSON.parse(e.data));
        this.setEventData(eventData);
      });
      this.sseClient.onerror = (event) => {
        this.readyState = event.target.readyState;
        this.sseClient.close();
        this.reconnectSse();
      };
    },
    reconnectSse() {
      let sseOK = false;
      if (this.sseClient === null) {
        sseOK = false;
      } else {
        sseOK = (this.sseClient.readyState !== EventSource.CLOSED);
      }
      this.readyState = this.sseClient.readyState;
      if (!sseOK) {
        this.sseInit();
      }
    },
    setEventData(data) {
      const {
        elementName,
        shift,
        shiftName,
        date,
      } = data;
      const canSetData = (this.currentShift === shift || this.currentShift === shiftName)
      && this.currentDate === date;
      if (canSetData) {
        if (elementName === 'cycletime') {
          this.setProduction(data);
        } else if (elementName === 'downtime') {
          this.setDowntime(data);
        }
      }
    },
    setProduction(data) {
      const {
        machinename,
        planid,
        partname,
        qty,
        activecavity,
        updatedAtTimestamp: updatedAt,
        shiftAvailableTime,
        sctm,
        actm_sum: ctSum,
        actm_count: ctCount,
        performance,
      } = data;
      this.machines.forEach((m, index) => {
        if (m.machinename === machinename) {
          const payload = {
            ...m,
            performance,
            machinestatus: 'UP',
            updatedAt,
          };
          const timeTarget = Math.floor((shiftAvailableTime / sctm) * activecavity);
          if (planid === m.planid) {
            let productions = [...m.production];
            const partIndex = m.production.findIndex((prod) => prod.partname === partname);
            if (partIndex > -1) {
              productions[partIndex].produced = qty;
              productions[partIndex].timeTarget = timeTarget;
            } else {
              productions = [...productions, {
                partname,
                planid,
                produced: qty,
                timeTarget,
                stdCycletime: sctm / 1000,
                avgCycletime: (ctSum / 1000) / ctCount,
              }];
            }
            payload.production = productions;
          } else {
            payload.planid = planid;
            payload.production = [{
              partname,
              planid,
              produced: qty,
              timeTarget,
              stdCycletime: sctm / 1000,
              avgCycletime: (ctSum / 1000) / ctCount,
            }];
          }
          this.setMachine({ index, payload });
        }
      });
    },
    setDowntime(data) {
      const {
        machinename,
        actualdowntimestart,
        downtimeend,
        downtimestart,
        reasonname,
        status,
      } = data;
      for (let i = 0; i < this.machines.length; i += 1) {
        if (this.machines[i].machinename === machinename) {
          let payload = {};
          if (status === 'inProgress') {
            payload = {
              ...this.machines[i],
              machinestatus: 'DOWN',
              updatedAt: this.currentTime,
              downsince: actualdowntimestart,
              downtimestart,
              downreason: reasonname || '',
            };
          } else if (status === 'complete' && downtimeend > this.machines[i].downtimestart) {
            payload = {
              ...this.machines[i],
              machinestatus: 'UP',
              updatedAt: this.currentTime,
            };
          } else {
            payload = {
              ...this.machines[i],
              machinestatus: 'DOWN',
            };
          }
          this.setMachine({ index: i, payload });
        }
      }
    },
  },
};
</script>
