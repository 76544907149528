<template>
  <v-select
    multiple
    return-object
    item-text="name"
    v-model="status"
    :items="statusList"
    label="STATUS"
  >
    <template #selection="{ item, attrs, selected }">
      <v-chip
        :key="JSON.stringify(item)"
        v-bind="attrs"
        small
        :input-value="selected"
        :color="color(item.value).color"
      >
        {{ item.name }}
      </v-chip>
    </template>
  </v-select>
</template>

<script>
import {
  mapMutations,
  mapGetters,
} from 'vuex';

const FIELD_NAME = 'machinestatus';

export default {
  name: 'StatusFilter',
  data() {
    return {
      status: [],
      statusList: [{
        name: 'Operational',
        value: 'UP',
      }, {
        name: 'Stopped',
        value: 'DOWN',
      }, {
        name: 'Idle',
        value: 'NOPLAN',
      }],
    };
  },
  created() {
    if (this.filter) {
      const value = this.statusList.filter((s) => this.filter.value.includes(s.value));
      if (value) {
        this.status = [...value];
      }
    } else {
      this.status = [...this.statusList];
    }
  },
  computed: {
    ...mapGetters('webApp', ['filters']),
    filter() {
      return this.filters && this.filters[FIELD_NAME];
    },
  },
  methods: {
    ...mapMutations('webApp', ['setFilter']),
    color(status) {
      let result = { text: 'NOPLAN', color: 'info' };
      if (status === 'UP') {
        result = { text: 'UP', color: 'success' };
      } else if (status === 'DOWN') {
        result = { text: 'DOWN', color: 'error' };
      }
      return result;
    },
  },
  watch: {
    status: {
      deep: true,
      handler(val) {
        if (this.status) {
          this.setFilter({
            field: FIELD_NAME,
            value: {
              value: val.map((s) => s.value),
              operation: 'includes',
            },
          });
        }
      },
    },
  },
};
</script>
